import './hero.scss';

function Hero() {
  return (
    <section id='hero' className='hero' style={{height: window.innerHeight}}>
      <div className="container">
        <header className='header'>
          <h1 className='title'>geekle.ai</h1>
          <h2 className='subtitle'>
            <span className='red'>/ labs</span>
            <span className='green'>/ learning</span>
            <span className='purpule'>/ AI assistants</span>
          </h2>
        </header>
        <p className='text'>COMING SOON</p>
      </div>
    </section>
  );
}

export default Hero;
