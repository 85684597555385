import './App.scss';
import Hero from './components/hero/Hero'

function App() {
  return (
    <main>
      <Hero />
    </main>
  );
}

export default App;
